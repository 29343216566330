import React, { useState } from 'react';
import { Button, Header, Input } from '../../components';
import Loading from '../../components/Loading';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useStateContext } from '../../contexts/ContextProvider';
import axiosClient from '../../axios-client';
import Alert from '../../components/Alert';
import { toast } from 'react-toastify';
import { GiCancel } from 'react-icons/gi';
import { IoIosSave } from 'react-icons/io';
import Select from 'react-select';
import ActionButton from '../../components/ActionButton';
import { FaBan, FaCheck, FaEye } from 'react-icons/fa';

const VoirDepense = ({ onClose, depense }) => {
    const [loading, setLoading] = useState(false);

    const { token, setToken, user } = useStateContext();
    const [errors, setErrors] = useState(null);

    const navigate = useNavigate();
    const valider = () => {
        axiosClient.put('/depense/' + depense.id)
            .then((data) => {
                toast.success('Opération effectuée avec succès!', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }).catch(err => {
                const response = err.response;
                if (response.status === 401) {
                    setToken(null);
                }
                else {
                    if (response.status === 422) {
                        setErrors(response.data.errors);
                        setLoading(false);
                        toast.error('Merci de verifier les informations a saisies!', {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        });
                    } else {
                        if (response.status === 403) {
                            navigate('/403');
                        } else {
                            if (response.status === 404) {
                                navigate('/404');
                            }
                            else {
                                navigate('/500');
                            }
                        }
                    }

                }
            })
    }
    const annuler = () => {
        axiosClient.put('/annulerDepense/' + depense.id)
            .then((data) => {
                toast.success('Opération effectuée avec succès!', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }).catch(err => {
                const response = err.response;
                if (response.status === 401) {
                    setToken(null);
                }
                else {
                    if (response.status === 422) {
                        setErrors(response.data.errors);
                        setLoading(false);
                        toast.error('Merci de verifier les informations a saisies!', {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        });
                    } else {
                        if (response.status === 403) {
                            navigate('/403');
                        } else {
                            if (response.status === 404) {
                                navigate('/404');
                            }
                            else {
                                navigate('/500');
                            }
                        }
                    }

                }
            })
    }



    useEffect(() => {

    }, []);

    return (
        <>
            <div className=' fixed inset-0 h-full bg-black bg-opacity-25 backdrop-blur-sm  '>
                <div className='h-screen flex justify-center items-center'>
                    <div className='flex-col max-h-screen overflow-y-auto bg-white lg:p-10 xl:p-10  p-2 md:p-10 w-auto md:w-1/2 lg:w-1/2 xl:w-1/2 rounded-xl'>
                        <div className=' flex justify-between'>
                            <Header title="Détail dépense" />
                            <div className="flex mt-2">
                                <ActionButton onClick={() => { }} color="bg-green-500" icon={<FaEye />} textColor="text-white" title="Voir bon" />
                            </div>
                        </div>
                        {errors &&
                            Object.keys(errors).map(key => (
                                <Alert key={key} message={errors[key][0]} />
                            ))
                        }
                        {loading ? <Loading /> :
                            <>
                                <div className='flex flex-wrap md:flex-nowrap lg:flex-nowrap xl:flex-nowrap gap-3'>
                                    <div className='flex-col w-1/2 justify-evenly '>
                                        <div className='mr-2'>
                                            <Input label="Numero" type="text" value={depense.num} />
                                        </div>
                                        <div className='mr-2'>
                                            <Input label="Montant" type="number" value={depense.montant} />
                                        </div>
                                        <div className='mr-2'>
                                            <Input label="Titre" type="text" value={depense.titre} />
                                        </div>
                                        <div className='mr-2'>
                                            <Input label="Bénéficiaire" type="text" value={depense.beneficiaire} />
                                        </div>
                                        <div className='mr-2'>
                                            <Input label="Donneur d'ordre" type="text" value={depense.donneurOrdre} />
                                        </div>
                                        <div className='mr-2'>
                                            <Input label="Note" type="text" value={depense.note} />
                                        </div>
                                        {depense.valide === true ?
                                            <div className=' flex  font-bold mt-2 text-sm justify-between'>
                                                V: <span > {depense.validateur}</span> à <span> {depense.dateValidation}</span>
                                            </div>
                                            : <></>
                                        }


                                    </div>
                                    <div className="flex w-1/2">
                                        <img src={depense.bon} alt="" />
                                    </div>
                                </div>
                                <div className='flex  justify-end items-end'>
                                    <div className=' flex gap-3 m-2  justify-between'>
                                        {user.role && (user.role.name === 'admin' || user.role.name === 'controleur') ?
                                            <>
                                                {depense.valide === false ?
                                                    <Button icon={<FaCheck />} onClick={() => { valider() }} color="ml-1 bg-green-500" textColor="text-white" text="Valider" />
                                                    :
                                                    <>
                                                        {user.role && (user.role.name === 'admin') ?
                                                            <Button icon={<FaBan />} onClick={() => { annuler() }} color="ml-1 bg-yellow-500" textColor="text-white" text="Annuler" />
                                                            : <></>
                                                        }
                                                    </>

                                                }
                                            </>
                                            : <></>

                                        }
                                        <Button icon={<GiCancel />} onClick={() => onClose()} color="bg-red-500" textColor="text-white" text="Fermer" />
                                    </div>
                                </div>
                            </>
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default VoirDepense