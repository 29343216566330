import React, { useEffect, useRef, useState } from 'react'
import Select from 'react-select';
import axiosClient from '../../axios-client';
import Input from '../../components/Input';
import { Button, Header } from '../../components';
import Alert from '../../components/Alert';
import { useStateContext } from '../../contexts/ContextProvider';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';
import Loading from '../../components/Loading';
import { BiSave } from 'react-icons/bi';
import { IoIosSave } from 'react-icons/io';
import { GiCancel } from 'react-icons/gi';

const EditUser = ({ onClose, editedUser }) => {
  const { id } = useParams();
  const [items, setItems] = useState([]);
  const [inputValue, setValue] = useState('');
  const [selectedValue, setSelectedValue] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingInfo, setLoadingInfo] = useState(false);
  const [errors, setErrors] = useState(null);
  const { token, setToken } = useStateContext();

  const [user, setUser] = useState({
    id: editedUser.id,
    username: '',
    nom: editedUser.nom,
    password: '',
    password_confirmation: '',
    hent: editedUser.hent,
    aent: editedUser.aent,
    hsor: editedUser.hsor,
    asor: editedUser.asor,
    hali: editedUser.hali,
    aali: editedUser.aali,
    hdep: editedUser.hdep,
    adep: editedUser.adep,
    hpai: editedUser.hpai,
    apai: editedUser.apai,
    hdec: editedUser.hdec,
    adec: editedUser.adec,
    hconnect:editedUser.hconnect,
    hsit:editedUser.hsit,
  });
  const navigate = useNavigate();
  // handle input change event
  const handleInputChange = value => {
    setValue(value);
  };

  // handle selection
  const handleChange = value => {
    setSelectedValue(value);
    setUser({ ...user, agence: value })

  }

  useEffect(() => {

  }, [])

  const getAgences = () => {
    axiosClient.get('/agences')
      .then((data) => {

        setItems(data.data.agences);

      })
  }

  const onSubmit = () => {

    setErrors(null);
    setLoading(true);
    axiosClient.put('/user/update', user)
      .then((response) => {
        setLoading(false);
        toast.success('Opération effectuée avec succès!', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        onClose();
      }).catch(err => {
        const response = err.response;
        if (response.status === 401) {
          setToken(null);
        }
        else {
          if (response.status === 422) {
            setErrors(response.data.errors);
            setLoading(false);
            toast.error('Une erreur est survenue!', {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
          } else {
            if (response.status === 403) {
              navigate('/403');
            } else {
              if (response.status === 404) {
                navigate('/404');
              }
              else {
                navigate('/500');
              }
            }
          }

        }
      })
  }
  return (
    <>
      <div className=' fixed inset-0 h-full bg-black bg-opacity-25 backdrop-blur-sm  '>
        <div className='h-screen flex justify-center items-center'>
          <div className='flex-col max-h-screen overflow-y-auto w-auto md:w-1/2 lg:w-1/2 xl:w-1/2 bg-white lg:p-10 xl:p-10  p-2 md:p-10   rounded-xl'>
            <div className=''>
              <Header title="Modifier utilisateur" />
            </div>
            {errors &&
              Object.keys(errors).map(key => (
                <Alert key={key} message={errors[key][0]} />
              ))
            }

            <div className="flex gap-2">

              <div className="flex-col">
                <Input label="Historique Entree" type="text" value={user.hent} onChange={ev => setUser({ ...user, hent: ev.target.value })} required="required" />
                <Input label="Ajout Entree" type="text" value={user.aent} onChange={ev => setUser({ ...user, aent: ev.target.value })} required="required" />
                <Input label="Historique Sortie" type="text" value={user.hsor} onChange={ev => setUser({ ...user, hsor: ev.target.value })} required="required" />
                <Input label="Ajout Sortie" type="text" value={user.asor} onChange={ev => setUser({ ...user, asor: ev.target.value })} required="required" />
                <Input label="Historique Dechargement" type="text" value={user.hdec} onChange={ev => setUser({ ...user, hdec: ev.target.value })} required="required" />
                <Input label="Ajout Dechargement" type="text" value={user.adec} onChange={ev => setUser({ ...user, adec: ev.target.value })} required="required" />
              </div>
              <div className="flex-col">
                
                <Input label="Historique Alimentation" type="text" value={user.hali} onChange={ev => setUser({ ...user, hali: ev.target.value })} required="required" />
                <Input label="Ajout Alimentation" type="text" value={user.aali} onChange={ev => setUser({ ...user, aali: ev.target.value })} required="required" />
                <Input label="Historique Depense" type="text" value={user.hdep} onChange={ev => setUser({ ...user, hdep: ev.target.value })} required="required" />
                <Input label="Ajout Depense" type="text" value={user.adep} onChange={ev => setUser({ ...user, adep: ev.target.value })} required="required" />
                <Input label="Historique Paiement" type="text" value={user.hpai} onChange={ev => setUser({ ...user, hpai: ev.target.value })} required="required" />
                <Input label="Ajout Paiement" type="text" value={user.apai} onChange={ev => setUser({ ...user, apai: ev.target.value })} required="required" />
              </div>
              <div className="flex-col">
                <Input label="HConnect" type="text" value={user.hconnect} onChange={ev => setUser({ ...user, hconnect: ev.target.value })} required="required" />
                <Input label="HSit" type="text" value={user.hsit} onChange={ev => setUser({ ...user, hsit: ev.target.value })} required="required" />
                <Input label="Username" type="text" value={editedUser.username} onChange={ev => setUser({ ...user, username: ev.target.value })} required="required" />
                <Input label="Nom" type="text" value={user.nom} onChange={ev => setUser({ ...user, nom: ev.target.value })} required="required" />
                <Input label="Mots de passe" type="password" value={user.password} onChange={ev => setUser({ ...user, password: ev.target.value })} />
                <Input label="Confirmater le mots de passe" type="password" value={user.password_confirmation} onChange={ev => setUser({ ...user, password_confirmation: ev.target.value })} />
                <div className='flex  justify-end items-end'>
                  {loading ? <Loading /> :
                    <div className=' flex gap-3 m-2  justify-between'>
                      <Button icon={<IoIosSave />} onClick={onSubmit} color="ml-1 bg-green-500" textColor="text-white" text="Enregistrer" />
                      <Button icon={<GiCancel />} onClick={() => onClose()} color="bg-red-500" textColor="text-white" text="Fermer" />
                    </div>
                  }

                </div>
              </div>
            </div>
            {/* <Select className='mt-4 outline-1 ' placeholder="Selectionner une agence" cacheOptions defaultOptions value={selectedValue} getOptionLabel={e => e.libelle} getOptionValue={e => e.id} options={items} onInputChange={handleInputChange} onChange={handleChange} /> */}


          </div>
        </div>
      </div>
    </>
  )
}

export default EditUser