import React, { useState } from 'react';
import { Button, Header, Input } from '../../components';
import Loading from '../../components/Loading';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useStateContext } from '../../contexts/ContextProvider';
import axiosClient from '../../axios-client';
import Alert from '../../components/Alert';
import { toast } from 'react-toastify';
import { GiCancel } from 'react-icons/gi';
import { IoIosSave } from 'react-icons/io';
import Select from 'react-select';

const AddEntree = ({ onClose }) => {
    const [loading, setLoading] = useState(false);

    const { token, setToken } = useStateContext();
    const [errors, setErrors] = useState(null);
    const [zones, setZones] = useState([]);
    const [selectedZone, setSelectedZone] = useState(null);
    const [inputValueZone, setValueZone] = useState('');
    const [preuve, setPreuve] = useState();
    const [entree, setEntree] = useState({
        montant: 0,
        dateEntree: '',
        source: '',
        responsable: '',
        reference: '',
        note: '',

    });


    function handleChangeFile(event) {
        setPreuve(event.target.files[0]);

    }

    useEffect(() => {

    }, []);


    const navigate = useNavigate();

    const onSubmit = () => {

        setErrors(null);
        setLoading(true);
        const formData = new FormData();
        formData.append('note', entree.note);
        formData.append('montant', entree.montant);
        formData.append('reference', entree.reference);
        formData.append('responsable', entree.responsable);
        formData.append('source', entree.source);
        formData.append('dateEntree', entree.dateEntree);
        formData.append('montant', entree.montant);
        formData.append('preuve', preuve);
        axiosClient.post('/entree/save', formData)
            .then((response) => {
                setLoading(false);
                setErrors(null);
                toast.success('Opération effectuée avec succès!', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });

                onClose();
            }).catch(err => {
                const response = err.response;
                if (response.status === 401) {
                    setToken(null);
                }
                else {
                    if (response.status === 422) {
                        setErrors(response.data.errors);
                        setLoading(false);
                        toast.error('Une erreur est survenue!', {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        });
                    } else {
                        if (response.status === 403) {
                            navigate('/403');
                        } else {
                            if (response.status === 404) {
                                navigate('/404');
                            }
                            else {
                                navigate('/500');
                            }
                        }
                    }

                }
            })
    }



    return (
        <>
            <div className=' fixed inset-0 h-full bg-black bg-opacity-25 backdrop-blur-sm  '>
                <div className='h-screen flex justify-center items-center'>
                    <div className='flex-col bg-white lg:p-10 xl:p-10  p-2 md:p-10 w-auto rounded-xl'>
                        <div className=''>
                            <Header title="Ajouter une entrée" />
                        </div>
                        {errors &&
                            Object.keys(errors).map(key => (
                                <Alert key={key} message={errors[key][0]} />
                            ))
                        }

                        <>
                            <div className='flex flex-wrap md:flex-nowrap lg:flex-nowrap xl:flex-nowrap gap-3'>
                                <div className='flex-col w-full justify-evenly '>
                                    <div className="flex gap-2">
                                        <div className="flex-col">
                                            <div className='mr-2'>
                                                <Input label="Date" type="date" value={entree.dateEntree} onChange={ev => setEntree({ ...entree, dateEntree: ev.target.value })} required="required"/>
                                            </div>
                                            <div className='mr-2'>
                                                <Input label="Montant" type="number" value={entree.montant} onChange={ev => setEntree({ ...entree, montant: ev.target.value })} required="required"/>
                                            </div>
                                            <div className='mr-2'>
                                                <Input label="Source" type="text" value={entree.source} onChange={ev => setEntree({ ...entree, source: ev.target.value })} required="required" />
                                            </div>
                                            <div className='mr-2'>
                                                <Input label="Responsable" type="text" value={entree.responsable} onChange={ev => setEntree({ ...entree, responsable: ev.target.value })} required="required" />
                                            </div>
                                        </div>
                                        <div className="flex-col">
                                            <div className='mr-2'>
                                                <Input label="Reference" type="text" value={entree.reference} onChange={ev => setEntree({ ...entree, reference: ev.target.value })} required="required" />
                                            </div>
                                            <div className='mr-2'>
                                                <Input label="Prveure" type="file" onChange={handleChangeFile} required="required" />
                                            </div>
                                            <div className='mr-2'>
                                                <Input label="Note" type="text" value={entree.note} onChange={ev => setEntree({ ...entree, note: ev.target.value })} required="required" />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </>

                        <div className='flex  justify-end items-end'>
                            {loading ? <Loading /> :
                                <div className=' flex gap-3 m-2  justify-between'>
                                    <Button icon={<IoIosSave />} onClick={onSubmit} color="ml-1 bg-green-500" textColor="text-white" text="Enregistrer" />
                                    <Button icon={<GiCancel />} onClick={() => onClose()} color="bg-red-500" textColor="text-white" text="Fermer" />
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AddEntree