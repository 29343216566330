import React, { useState } from 'react';
import { Button, Header, Input } from '../../components';
import Loading from '../../components/Loading';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useStateContext } from '../../contexts/ContextProvider';
import axiosClient from '../../axios-client';
import Alert from '../../components/Alert';
import { toast } from 'react-toastify';
import { GiCancel } from 'react-icons/gi';
import { IoIosSave } from 'react-icons/io';
import Select from 'react-select';

const AddDistPaiement = ({ cai, onClose }) => {
    const [loading, setLoading] = useState(false);

    const { token, setToken } = useStateContext();
    const [errors, setErrors] = useState(null);
    const [services, setServices] = useState([]);
    const [selectedService, setSelectedService] = useState(null);
    const [inputValueService, setValueService] = useState('');
    const [bon, setBon] = useState(null);
    const [facture, setFacture] = useState(null);

    const [paiement, setPaiement] = useState({
        datePaiement: '',
        montant: '',
        service: '',
        nombre: '',
        note: '',
        operateur: '',
        caisse: cai.id
    });

    useEffect(() => {
        getServices();
    }, []);

    const handleInputChangeService = value => {
        setValueService(value);
    };

    // handle selection


    // handle selection
    const handleChangeService = value => {
        setSelectedService(value);
        setPaiement({ ...paiement, service: value.id });

    }
    function handleChangeBon(event) {
        setBon(event.target.files[0]);

    }
    function handleChangeFacture(event) {
        setFacture(event.target.files[0]);

    }
    const navigate = useNavigate();
    const getServices = () => {
        axiosClient.get('/services')
            .then((data) => {
                setServices(data.data.services);
            }).catch(err => {
                const response = err.response;
                if (response.status === 401) {
                    setToken(null);
                }
                else {
                    if (response.status === 422) {
                        setErrors(response.data.errors);
                        setLoading(false);
                        toast.error('Une erreur est survenue!', {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        });
                    } else {
                        if (response.status === 403) {
                            navigate('/403');
                        } else {
                            if (response.status === 404) {
                                navigate('/404');
                            }
                            else {
                                navigate('/500');
                            }
                        }
                    }

                }
            })
    }
    const onSubmit = () => {

        setErrors(null);
        setLoading(true);
        axiosClient.post('/paiement/save', paiement)
            .then((response) => {
                setLoading(false);
                setErrors(null);
                toast.success('Opération effectuée avec succès!', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });

                onClose();
            }).catch(err => {
                const response = err.response;
                if (response.status === 401) {
                    setToken(null);
                }
                else {
                    if (response.status === 422) {
                        setErrors(response.data.errors);
                        setLoading(false);
                        toast.error('Une erreur est survenue!', {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        });
                    } else {
                        if (response.status === 403) {
                            navigate('/403');
                        } else {
                            if (response.status === 404) {
                                navigate('/404');
                            }
                            else {
                                navigate('/500');
                            }
                        }
                    }

                }
            })
    }

    return (
        <>
            <div className=' fixed inset-0 h-full bg-black bg-opacity-25 backdrop-blur-sm  '>
                <div className='h-screen flex justify-center items-center'>
                    <div className='flex-col max-h-screen overflow-y-auto bg-white lg:p-10 xl:p-10  p-2 md:p-10 w-auto md:w-1/4 lg:w-1/4 xl:w-1/4 rounded-xl'>
                        <div className=''>
                            <Header title="Ajouter un paiement" />
                        </div>
                        {errors &&
                            Object.keys(errors).map(key => (
                                <Alert key={key} message={errors[key][0]} />
                            ))
                        }
                        <>
                            <div className='flex flex-wrap md:flex-nowrap lg:flex-nowrap xl:flex-nowrap gap-3'>
                                <div className='flex-col w-full justify-evenly '>
                                    <div className='mr-2'>
                                        <label className='font-bold' > Sous le compte de : <span className='text-red-700'>{cai.nom}</span></label>
                                    </div>
                                    <div className='mr-2'>
                                        <Input label="Date" type="date" value={paiement.datePaiement} onChange={ev => setPaiement({ ...paiement, datePaiement: ev.target.value })} required="required" />
                                    </div>
                                    <div className="flex-col w-full">
                                        <label className="block text-sm font-medium leading-6 text-gray-900">Service <span className='text-red-500'> *</span></label>
                                        <Select className='mt-1 outline-1 ' placeholder="Service" cacheOptions defaultOptions value={selectedService} getOptionLabel={e => e.libelle} getOptionValue={e => e.id} options={services} onInputChange={handleInputChangeService} onChange={handleChangeService} />
                                    </div>
                                    <div className='mr-2'>
                                        <Input label="Montant" type="number" value={paiement.montant} onChange={ev => setPaiement({ ...paiement, montant: ev.target.value })} required="required" />
                                    </div>
                                    <div className='mr-2'>
                                        <Input label="Nombre" type="number" value={paiement.nombre} onChange={ev => setPaiement({ ...paiement, nombre: ev.target.value })} required="required" />
                                    </div>
                                    <div className='mr-2'>
                                        <Input label="Operateur" type="text" value={paiement.operateur} onChange={ev => setPaiement({ ...paiement, operateur: ev.target.value })} required="required" />
                                    </div>
                                    <div className='mr-2'>
                                        <Input label="Note" type="text" value={paiement.note} onChange={ev => setPaiement({ ...paiement, note: ev.target.value })} />
                                    </div>
                                </div>
                            </div>
                            <div className='flex  justify-end items-end'>
                                {loading ? <Loading /> :
                                    <>
                                        <div className=' flex gap-3 m-2  justify-between'>
                                            <Button icon={<IoIosSave />} onClick={onSubmit} color="ml-1 bg-green-500" textColor="text-white" text="Enregistrer" />
                                            <Button icon={<GiCancel />} onClick={() => onClose()} color="bg-red-500" textColor="text-white" text="Fermer" />
                                        </div>
                                    </>

                                }
                            </div>
                        </>

                    </div>
                </div>
            </div>
        </>
    )
}

export default AddDistPaiement