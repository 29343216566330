import React, { useState } from 'react';
import { Button, Header, Input } from '../../components';
import Loading from '../../components/Loading';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useStateContext } from '../../contexts/ContextProvider';
import axiosClient from '../../axios-client';
import Alert from '../../components/Alert';
import { toast } from 'react-toastify';
import { GiCancel } from 'react-icons/gi';
import { IoIosSave } from 'react-icons/io';
import Select from 'react-select';

const AddDocument = ({ onClose }) => {
  const [loading, setLoading] = useState(false);

  const { token, setToken } = useStateContext();
  const [errors, setErrors] = useState(null);
  const [zones, setZones] = useState([]);
  const [selectedZone, setSelectedZone] = useState(null);
  const [inputValueZone, setValueZone] = useState('');
  const [typeDocuments, setTypeDocuments] = useState([]);
  const [selectedTypeDocument, setSelectedTypeDocument] = useState(null);
  const [inputValueTypeDocument, setValueTypeDocument] = useState('');
  const [preuve, setPreuve] = useState();
  const [document, setDocument] = useState({
    titre: '',
    dateDocument: '',
    
    note: '',
    type: '',

  });


  function handleChangeFile(event) {
    setPreuve(event.target.files[0]);

  }
  const handleInputChangeTypeDocument = value => {
    setValueTypeDocument(value);
  };

  // handle selection


  // handle selection
  const handleChangeTypeDocument = value => {
    setSelectedTypeDocument(value);
    setDocument({ ...document, type: value.id });

  }

  const getTypeDocuments = () => {
    axiosClient.get('/typeDocuments')
        .then((data) => {
            setTypeDocuments(data.data.typeDocuments);
        }).catch(err => {
            const response = err.response;
            if (response.status === 401) {
                setToken(null);
            }
            else {
                if (response.status === 422) {
                    setErrors(response.data.errors);
                    setLoading(false);
                    toast.error('Une Erreur est survenue!', {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                } else {
                    if (response.status === 403) {
                        navigate('/403');
                    } else {
                        if (response.status === 404) {
                            navigate('/404');
                        }
                        else {
                            navigate('/500');
                        }
                    }
                }

            }
        })
}

  useEffect(() => {
    getTypeDocuments();
  }, []);


  const navigate = useNavigate();

  const onSubmit = () => {

    setErrors(null);
    setLoading(true);
    const formData = new FormData();
    formData.append('titre', document.titre);
    formData.append('note', document.note);
    formData.append('typeDocument', document.type);
    formData.append('dateDocument', document.dateDocument);
    formData.append('document', preuve);
    axiosClient.post('/document/save', formData)
      .then((response) => {
        setLoading(false);
        setErrors(null);
        toast.success('Opération effectuée avec succès!', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });

        onClose();
      }).catch(err => {
        const response = err.response;
        if (response.status === 401) {
          setToken(null);
        }
        else {
          if (response.status === 422) {
            setErrors(response.data.errors);
            setLoading(false);
            toast.error('Une erreur est survenue!', {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
          } else {
            if (response.status === 403) {
              navigate('/403');
            } else {
              if (response.status === 404) {
                navigate('/404');
              }
              else {
                navigate('/500');
              }
            }
          }

        }
      })
  }



  return (
    <>
      <div className=' fixed inset-0 h-full bg-black bg-opacity-25 backdrop-blur-sm  '>
        <div className='h-screen flex justify-center items-center'>
          <div className='flex-col bg-white lg:p-10 xl:p-10  p-2 md:p-10 w-auto rounded-xl'>
            <div className=''>
              <Header title="Ajouter un document" />
            </div>
            {errors &&
              Object.keys(errors).map(key => (
                <Alert key={key} message={errors[key][0]} />
              ))
            }

            <>
              <div className='flex flex-wrap md:flex-nowrap lg:flex-nowrap xl:flex-nowrap gap-3'>
                <div className='flex-col w-full justify-evenly '>
                  <div className="flex gap-2">
                    <div className="flex-col">

                      <div className='mr-2'>
                        <Input label="Date Document" type="date" value={document.dateDocument} onChange={ev => setDocument({ ...document, dateDocument: ev.target.value })} />
                      </div>
                      <div className="flex-col w-full">
                        <label className="block text-sm font-medium leading-6 text-gray-900">Type <span className='text-red-500'> *</span></label>
                        <Select className='mt-1 outline-1 ' placeholder="Type" cacheOptions defaultOptions value={selectedTypeDocument} getOptionLabel={e => e.code} getOptionValue={e => e.id} options={typeDocuments} onInputChange={handleInputChangeTypeDocument} onChange={handleChangeTypeDocument} />
                      </div>
                      <div className='mr-2'>
                        <Input label="Titre" type="text" value={document.titre} onChange={ev => setDocument({ ...document, titre: ev.target.value })} required="required" />
                      </div>
                      <div className='mr-2'>
                        <Input label="Prveure" type="file" onChange={handleChangeFile} required="required" />
                      </div>
                      <div className='mr-2'>
                        <Input label="Note" type="text" value={document.note} onChange={ev => setDocument({ ...document, note: ev.target.value })} required="required" />
                      </div>
                    </div>

                  </div>
                </div>

              </div>
            </>

            <div className='flex  justify-end items-end'>
              {loading ? <Loading /> :
                <div className=' flex gap-3 m-2  justify-between'>
                  <Button icon={<IoIosSave />} onClick={onSubmit} color="ml-1 bg-green-500" textColor="text-white" text="Enregistrer" />
                  <Button icon={<GiCancel />} onClick={() => onClose()} color="bg-red-500" textColor="text-white" text="Fermer" />
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default AddDocument