import React, { useEffect, useState } from 'react'
import { BsFillBuildingFill } from 'react-icons/bs'
import { FaCashRegister, FaRegMoneyBillAlt } from 'react-icons/fa'
import { FiShoppingCart, FiUsers } from 'react-icons/fi'
import { NavLink, useNavigate } from 'react-router-dom'
import { TbMath } from "react-icons/tb";
import { useStateContext } from '../../contexts/ContextProvider'
import { FaFileLines, FaMoneyBill1Wave, FaRegCalendarDays } from 'react-icons/fa6'
import { BiArrowToTop } from "react-icons/bi";
import { MdManageHistory, MdTransitEnterexit } from "react-icons/md";
import { CiInboxOut } from "react-icons/ci";
import { FaBuildingColumns } from "react-icons/fa6";
import { GiCash } from "react-icons/gi";
import { IoGitBranch, IoLogInSharp } from 'react-icons/io5'
import axiosClient from '../../axios-client'
import { toast } from 'react-toastify'

const AdminRole = () => {
    const activeLink = 'flex items-center gap-5 pl-4 pt-3  bp-3 rounded-lg text-white bg-blue-600 text-md m-2';
    const normalLink = 'flex items-center gap-5 pl-4 pt-3  bp-3 rounded-lg  text-md text-gray-700 dark:text-gray-200 dark:hover:text-black hover:bg-light-gray m-2';
    const { setActiveMenu, screenSize, currentColor, setToken } = useStateContext();

    const [toDo, setToDo] = useState({
        totalAli: 0,
        totalSor: 0,
        totalEnt: 0,
        totalDep: 0,
        totalPai: 0,
        totalDec: 0,
    });
    const navigate = useNavigate();

    useEffect(() => {
        getToDo();
    }, [])
    const getToDo = () => {
        axiosClient.get('/toDo')
            .then((response) => {

                setToDo({
                    ...toDo, totalAli: response.data.totalAli, totalSor: response.data.totalSor,
                    totalEnt: response.data.totalEnt, totalDep: response.data.totalDep, totalPai: response.data.totalPai,
                    soldeRestant: response.data.soldeRestant, totalDec: response.data.totalDec,

                });

            }).catch(err => {
                const response = err.response;

                if (response.status === 401) {
                    setToken(null);
                }
                else {
                    if (response.status === 422) {

                        toast.error('Une erreur est survenue!', {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        });
                    } else {
                        if (response.status === 403) {
                            navigate('/403');
                        } else {
                            if (response.status === 404) {
                                navigate('/404');
                            }
                            else {
                                navigate('/500');
                            }
                        }
                    }

                }
            })

    }
   
    const handleCloseSidebar = () => {
        if (screenSize <= 900) {
            setActiveMenu(false);

        }
    }
    return (
        < >

            <div>
                <NavLink to={`/agences`} key='users' onClick={handleCloseSidebar}
                    className={({ isActive }) =>
                        isActive ? activeLink : normalLink
                    } style={({ isActive }) => isActive ? { backgroundColor: `${currentColor}` } : { backgroundColor: 'white' }}>
                    <FaBuildingColumns />
                    <span className='capitalize mb-2'>
                        Agences
                    </span>
                </NavLink>
                <NavLink to={`/users`} key='users' onClick={handleCloseSidebar}
                    className={({ isActive }) =>
                        isActive ? activeLink : normalLink
                    } style={({ isActive }) => isActive ? { backgroundColor: `${currentColor}` } : { backgroundColor: 'white' }}>
                    <FiUsers />
                    <span className='capitalize mb-2'>
                        Utilisateurs
                    </span>
                </NavLink>
                <NavLink to={`/caisseCentrales`} key='users' onClick={handleCloseSidebar}
                    className={({ isActive }) =>
                        isActive ? activeLink : normalLink
                    } style={({ isActive }) => isActive ? { backgroundColor: `${currentColor}` } : { backgroundColor: 'white' }}>
                    <GiCash />
                    <span className='capitalize mb-2'>
                        Caisse Centrale
                    </span>
                </NavLink>
                <NavLink to={`/caisses`} key='users' onClick={handleCloseSidebar}
                    className={({ isActive }) =>
                        isActive ? activeLink : normalLink
                    } style={({ isActive }) => isActive ? { backgroundColor: `${currentColor}` } : { backgroundColor: 'white' }}>
                    <FaCashRegister />
                    <span className='capitalize mb-2'>
                        Caisses
                    </span>
                </NavLink>
                <NavLink to={`/entrees`} key='entrees' onClick={handleCloseSidebar}
                    className={({ isActive }) =>
                        isActive ? activeLink : normalLink
                    } style={({ isActive }) => isActive ? { backgroundColor: `${currentColor}` } : { backgroundColor: 'white' }}>
                    <IoLogInSharp />
                    <div className="flex w-full justify-between">
                        <span className='capitalize mb-2'>
                            Entrées
                        </span>
                        <div className="rounded-full mb-2 mr-2 px-1 bg-red-400">
                            <span className='capitalize '>
                                {toDo.totalEnt}
                            </span>
                        </div>
                    </div>
                </NavLink>
                <NavLink to={`/depenses`} key='despenses' onClick={handleCloseSidebar}
                    className={({ isActive }) =>
                        isActive ? activeLink : normalLink
                    } style={({ isActive }) => isActive ? { backgroundColor: `${currentColor}` } : { backgroundColor: 'white' }}>
                    <FiShoppingCart />
                    <div className="flex w-full justify-between">
                        <span className='capitalize mb-2'>
                            Dépenses
                        </span>
                        <div className="rounded-full mb-2 mr-2 px-1 bg-red-400">
                            <span className='capitalize '>
                                {toDo.totalDep}
                            </span>
                        </div>
                    </div>
                </NavLink>
                <NavLink to={`/alimentations`} key='alimentations' onClick={handleCloseSidebar}
                    className={({ isActive }) =>
                        isActive ? activeLink : normalLink
                    } style={({ isActive }) => isActive ? { backgroundColor: `${currentColor}` } : { backgroundColor: 'white' }}>
                    <BiArrowToTop />
                    <div className="flex w-full justify-between">
                        <span className='capitalize mb-2'>
                            Alimentations
                        </span>
                        <div className="rounded-full mb-2 mr-2 px-1 bg-red-400">
                            <span className='capitalize '>
                                {toDo.totalAli}
                            </span>
                        </div>
                    </div>
                </NavLink>
                <NavLink to={`/dechargements`} key='dechargements' onClick={handleCloseSidebar}
                    className={({ isActive }) =>
                        isActive ? activeLink : normalLink
                    } style={({ isActive }) => isActive ? { backgroundColor: `${currentColor}` } : { backgroundColor: 'white' }}>
                    <MdTransitEnterexit />
                    <div className="flex w-full justify-between">
                        <span className='capitalize mb-2'>
                            Dechargements
                        </span>
                        <div className="rounded-full mb-2 mr-2 px-1 bg-red-400">
                            <span className='capitalize '>
                                {toDo.totalDec}
                            </span>
                        </div>
                    </div>
                </NavLink>
                <NavLink to={`/sorties`} key='sorties' onClick={handleCloseSidebar}
                    className={({ isActive }) =>
                        isActive ? activeLink : normalLink
                    } style={({ isActive }) => isActive ? { backgroundColor: `${currentColor}` } : { backgroundColor: 'white' }}>
                    <CiInboxOut />
                    <div className="flex w-full justify-between">
                        <span className='capitalize mb-2'>
                            Sorties
                        </span>
                        <div className="rounded-full mb-2 mr-2 px-1 bg-red-400">
                            <span className='capitalize '>
                                {toDo.totalSor}
                            </span>
                        </div>
                    </div>
                </NavLink>
                <NavLink to={`/situations`} key='situations' onClick={handleCloseSidebar}
                    className={({ isActive }) =>
                        isActive ? activeLink : normalLink
                    } style={({ isActive }) => isActive ? { backgroundColor: `${currentColor}` } : { backgroundColor: 'white' }}>
                    <FaRegCalendarDays />
                    <span className='capitalize mb-2'>
                        Situations CNT
                    </span>
                </NavLink>
                <NavLink to={`/paiements`} key='paiements' onClick={handleCloseSidebar}
                    className={({ isActive }) =>
                        isActive ? activeLink : normalLink
                    } style={({ isActive }) => isActive ? { backgroundColor: `${currentColor}` } : { backgroundColor: 'white' }}>
                    <FaMoneyBill1Wave />
                    <div className="flex w-full justify-between">
                        <span className='capitalize mb-2'>
                            Paiements
                        </span>
                        <div className="rounded-full mb-2 mr-2 px-1 bg-red-400">
                            <span className='capitalize '>
                                {toDo.totalPai}
                            </span>
                        </div>
                    </div>
                </NavLink>
                <NavLink to={`/situationcaisse`} key='situationcaisse' onClick={handleCloseSidebar}
                    className={({ isActive }) =>
                        isActive ? activeLink : normalLink
                    } style={({ isActive }) => isActive ? { backgroundColor: `${currentColor}` } : { backgroundColor: 'white' }}>
                    <FaRegCalendarDays />
                    <span className='capitalize mb-2'>
                        Situations POS
                    </span>
                </NavLink>
                <NavLink to={`/privileges`} key='privilages' onClick={handleCloseSidebar}
                    className={({ isActive }) =>
                        isActive ? activeLink : normalLink
                    } style={({ isActive }) => isActive ? { backgroundColor: `${currentColor}` } : { backgroundColor: 'white' }}>
                    <IoGitBranch />
                    <span className='capitalize mb-2'>
                        Privilèges
                    </span>
                </NavLink>
                <NavLink to={`/documents`} key='documents' onClick={handleCloseSidebar}
                    className={({ isActive }) =>
                        isActive ? activeLink : normalLink
                    } style={({ isActive }) => isActive ? { backgroundColor: `${currentColor}` } : { backgroundColor: 'white' }}>
                    <FaFileLines />
                    <span className='capitalize mb-2'>
                        Documents
                    </span>
                </NavLink>

                <NavLink to={`/historiques`} key='historiques' onClick={handleCloseSidebar}
                    className={({ isActive }) =>
                        isActive ? activeLink : normalLink
                    } style={({ isActive }) => isActive ? { backgroundColor: `${currentColor}` } : { backgroundColor: 'white' }}>
                    <MdManageHistory />
                    <span className='capitalize mb-2'>
                        Historiques
                    </span>
                </NavLink>

            </div>
        </>
    )
}

export default AdminRole