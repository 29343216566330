import React, { useState } from 'react';
import { Button, Header, Input } from '../../components';
import Loading from '../../components/Loading';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useStateContext } from '../../contexts/ContextProvider';
import axiosClient from '../../axios-client';
import Alert from '../../components/Alert';
import { toast } from 'react-toastify';
import { GiCancel } from 'react-icons/gi';
import { IoIosSave } from 'react-icons/io';
import Select from 'react-select';

const AddSortie = ({ onClose }) => {
    const [loading, setLoading] = useState(false);

    const { token, setToken } = useStateContext();
    const [errors, setErrors] = useState(null);
    const [zones, setZones] = useState([]);
    const [selectedZone, setSelectedZone] = useState(null);
    const [inputValueZone, setValueZone] = useState('');
    const [preuve, setPreuve] = useState();
    const [sortie, setSortie] = useState({
        

    });


    function handleChangeFile(event) {
        setPreuve(event.target.files[0]);

    }

    useEffect(() => {

    }, []);


    const navigate = useNavigate();

    const onSubmit = () => {

        setErrors(null);
        setLoading(true);
        const formData = new FormData();
        formData.append('note', sortie.note);
        formData.append('dateSortie', sortie.dateSortie);
        formData.append('montant', sortie.montant);
        formData.append('reference', sortie.reference);
        formData.append('responsable', sortie.responsable);
        formData.append('destination', sortie.destination);
        formData.append('montant', sortie.montant);
        formData.append('preuve', preuve);
        formData.append('numOrdre',sortie.numOrdre);
        formData.append('titre',sortie.titre);
        axiosClient.post('/sortie/save', formData)
            .then((response) => {
                setLoading(false);
                setErrors(null);
                toast.success('Opération effectuée avec succès!', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });

                onClose();
            }).catch(err => {
                const response = err.response;
                if (response.status === 401) {
                    setToken(null);
                }
                else {
                    if (response.status === 422) {
                        setErrors(response.data.errors);
                        setLoading(false);
                        toast.error('Une erreur est survenue!', {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        });
                    } else {
                        if (response.status === 403) {
                            navigate('/403');
                        } else {
                            if (response.status === 404) {
                                navigate('/404');
                            }
                            else {
                                navigate('/500');
                            }
                        }
                    }

                }
            })
    }



    return (
        <>
            <div className=' fixed inset-0 h-full bg-black bg-opacity-25 backdrop-blur-sm  '>
                <div className='h-screen flex justify-center items-center'>
                    <div className='flex-col bg-white lg:p-10 xl:p-10  p-2 md:p-10 w-auto rounded-xl'>
                        <div className=''>
                            <Header title="Ajouter une sortie" />
                        </div>
                        {errors &&
                            Object.keys(errors).map(key => (
                                <Alert key={key} message={errors[key][0]} />
                            ))
                        }
                        {loading ? <Loading /> :
                            <>
                                <div className='flex flex-wrap md:flex-nowrap lg:flex-nowrap xl:flex-nowrap gap-3'>
                                    <div className='flex-col w-full justify-evenly '>
                                        <div className="flex gap-2">
                                            <div className="flex-col">
                                                <div className='mr-2'>
                                                    <Input label="Date" type="date" value={sortie.dateSortie} onChange={ev => setSortie({ ...sortie, dateSortie: ev.target.value })} required="required" />
                                                </div>
                                                <div className='mr-2'>
                                                    <Input label="Montant" type="number" value={sortie.montant} onChange={ev => setSortie({ ...sortie, montant: ev.target.value })} required="required" />
                                                </div>
                                                <div className='mr-2'>
                                                    <Input label="Titre" type="text" value={sortie.titre} onChange={ev => setSortie({ ...sortie, titre: ev.target.value })} required="required" />
                                                </div>
                                                <div className='mr-2'>
                                                    <Input label="Responsable" type="text" value={sortie.responsable} onChange={ev => setSortie({ ...sortie, responsable: ev.target.value })} required="required" />
                                                </div>
                                                <div className='mr-2'>
                                                    <Input label="numOrdre" type="text" value={sortie.numOrdre} onChange={ev => setSortie({ ...sortie, numOrdre: ev.target.value })} required="required" />
                                                </div>
                                            </div>
                                            <div className="flex-col">
                                                <div className='mr-2'>
                                                    <Input label="Reference" type="text" value={sortie.reference} onChange={ev => setSortie({ ...sortie, reference: ev.target.value })} required="required" />
                                                </div>
                                                <div className='mr-2'>
                                                    <Input label="Destination" type="text" value={sortie.destination} onChange={ev => setSortie({ ...sortie, destination: ev.target.value })} required="required" />
                                                </div>
                                                <div className='mr-2'>
                                                    <Input label="Prveure" type="file" onChange={handleChangeFile} required="required" />
                                                </div>
                                                <div className='mr-2'>
                                                    <Input label="Note" type="text" value={sortie.note} onChange={ev => setSortie({ ...sortie, note: ev.target.value })} required="required" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </>
                        }

                        <div className='flex  justify-end items-end'>
                            <div className=' flex gap-3 m-2  justify-between'>
                                <Button icon={<IoIosSave />} onClick={onSubmit} color="ml-1 bg-green-500" textColor="text-white" text="Enregistrer" />
                                <Button icon={<GiCancel />} onClick={() => onClose()} color="bg-red-500" textColor="text-white" text="Fermer" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AddSortie