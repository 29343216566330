import React, { useEffect, useRef, useState } from 'react';
import { Button, Header, Input } from '../../components';
import Alert from '../../components/Alert';
import { IoCalendarOutline } from "react-icons/io5";
import { BsCalendar4Range } from "react-icons/bs";
import Loading from '../../components/Loading';
import { DownloadTableExcel } from 'react-export-table-to-excel';
import { FaRegFileExcel } from 'react-icons/fa6';
import axiosClient from '../../axios-client';
import { useStateContext } from '../../contexts/ContextProvider';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import ReactToPrint from 'react-to-print';
import { FaPrint } from 'react-icons/fa';
import Icon from '../../data/icon.png';
import NumberFormat from '../../components/NumberFormat';
import Select from 'react-select';
const Situation = () => {
  const [errors, setErrors] = useState(null);

  const [showSituation, setShowSituation] = useState(false);
  const [initial, setInitial] = useState(false);
  const { token, setToken, user } = useStateContext();
  const [loadingSituation, setLoadingSituation] = useState(false);
  const [caisseCentrales, setCaisseCentrales] = useState([]);
  const [selectedCaisseCentrale, setSelectedCaisseCentrale] = useState(null);
  const [inputValueCaisseCentrale, setValueCaisseCentrale] = useState('');



  const situationRef = useRef();

  const situationPrintRef = useRef();


  const [situation, setSituation] = useState({
    soldeDepart: 0,
    totalEntree: 0,
    totalSortie: 0,
    totalAlimentation: 0,
    totalDechargement: 0,
    soldeRestant: 0,
    caisseCentrale: {},
    start: '',
    end: '',
    typeSit: ''
  });


  const [req, setReq] = useState({
    DateDebut: '',
    DateFin: '',
    caisseCentrale: '',
    type: '',
  });

  const navigate = useNavigate();

  useEffect(() => {
    if (user.role && (user.role.name === 'admin' || user.role.name === 'controleur')) {
      getCaisseCentrales();
    }
  }, [])
  const handleInputChangeCaisseCentrale = value => {
    setValueCaisseCentrale(value);
  };

  // handle selection


  // handle selection
  const handleChangeCaisseCentrale = value => {
    setSelectedCaisseCentrale(value);
    setReq({ ...req, caisseCentrale: value.id });

  }
  const getCaisseCentrales = () => {
    axiosClient.get('/caisseCentrales')
      .then((data) => {
        setCaisseCentrales(data.data.caisseCentrales);
      }).catch(err => {
        const response = err.response;
        if (response.status === 401) {
          setToken(null);
        }
        else {
          if (response.status === 422) {
            setErrors(response.data.errors);
            toast.error('Une erreur est survenue!', {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
          } else {
            if (response.status === 403) {
              navigate('/403');
            } else {
              if (response.status === 404) {
                navigate('/404');
              }
              else {
                navigate('/500');
              }
            }
          }

        }
      })
  }

  const getSituation = () => {
    setInitial(true);
    setInitial(false);
    setShowSituation(true);
    setLoadingSituation(true);
    setErrors(null);
    axiosClient.get('/situationCaisseCentrale?DateDebut=' + req.DateDebut + '&DateFin=' + req.DateFin + '&caisseCentrale=' + req.caisseCentrale + '&type=' + req.type)
      .then((response) => {
        console.log(response.data);
        setSituation({
          ...situation, soldeDepart: response.data.soldeDepart, totalAlimentation: response.data.totalAlimentation,
          totalEntree: response.data.totalEntree, totalSortie: response.data.totalSortie, totalDechargement: response.data.totalDechargement,
          soldeRestant: response.data.soldeRestant,
          caisseCentrale: response.data.caisseCentrale,
          start: response.data.start,
          end: response.data.end,
          typeSit: response.data.typeSit,
        });
        setLoadingSituation(false);
      }).catch(err => {
        const response = err.response;
        setShowSituation(false);
        setLoadingSituation(false);
        if (response.status === 401) {
          setToken(null);
        }
        else {
          if (response.status === 422) {
            setErrors(response.data.errors);

            toast.error('Une erreur est survenue!', {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
          } else {
            if (response.status === 403) {
              navigate('/403');
            } else {
              if (response.status === 404) {
                navigate('/404');
              }
              else {
                navigate('/500');
              }
            }
          }

        }
      })

  }


  return (
    <>
      <Header title="Situation Caisse Centrale" />
      {errors &&
        Object.keys(errors).map(key => (
          <Alert key={key} message={errors[key][0]} />
        ))
      }
      <div className='flex gap-2 w-full flex-wrap md:flex-nowrap lg:flex-nowrap xl:flex-nowrap justify-between items-center'>
        <Input label="Date debut" type="date" value={req.DateDebut} onChange={ev => setReq({ ...req, DateDebut: ev.target.value })} required="required" />
        <Input label="Date fin" type="date" value={req.DateFin} onChange={ev => setReq({ ...req, DateFin: ev.target.value })} required="required" />
        <Input label="Type: (N ou V)" type="text" value={req.type} onChange={ev => setReq({ ...req, type: ev.target.value })} required="required" />
        {user.role && (user.role.name === 'admin' || user.role.name === 'controleur') ?
          <>
            <div className="flex-col w-full">
              <label className="block text-sm font-medium leading-6 text-gray-900">Caisse centrale <span className='text-red-500'> *</span></label>
              <Select className='mt-1 outline-1 ' placeholder="Caisse Centrale" cacheOptions defaultOptions value={selectedCaisseCentrale} getOptionLabel={e => e.nom} getOptionValue={e => e.id} options={caisseCentrales} onInputChange={handleInputChangeCaisseCentrale} onChange={handleChangeCaisseCentrale} />
            </div>

          </>
          :
          <></>

        }
        <div className='mt-8'>
          <Button onClick={() => getSituation()} color="bg-green-500" icon={<BsCalendar4Range />} size="20" text="Voir" textColor="text-white" />
        </div>
      </div>
      <div className='flex flex-col gap-4 mt-4'>
        {initial ? <></> :

          <>
            {showSituation ?
              <>
                {loadingSituation ? <Loading /> :
                  <>
                    <div className="flex justify-end items-end gap-2">
                      <ReactToPrint
                        trigger={() => {
                          return <Button icon={<FaPrint />} color="ml-1 bg-blue-500" textColor="text-white" text="Imprimer" />
                        }}
                        content={() => situationPrintRef.current}
                        documentTitle='Situation'
                        pageStyle='situation'
                      />

                    </div>
                    <div className="mt-3 w-full overflow-x-auto">
                      <div ref={situationPrintRef} className="p-3">
                        <table className='table-auto'>
                          <thead>
                            <tr>
                              <td>
                                <div className="flex  gap-1">
                                  <img className=" h-28 w-28" src={Icon} alt="Your Company" />
                                  <div className="flex flex-col gap-3">
                                    <h1 className='font-bold '>Situation Caisse centrale</h1>
                                    <h1>Code Caisse centrale: {situation.caisseCentrale.code}</h1>
                                    <h1>Libelle : {situation.caisseCentrale.nom}</h1>
                                    <h1>Agence : {situation.caisseCentrale.user.agence.nom}</h1>
                                  </div>
                                </div>
                                <div className="flex w-full my-4 justify-center items-center">
                                  <h1>Situation Caisse Centrale du: {situation.start} au: {situation.end}</h1>
                                </div>
                                <div className="flex w-full my-1 justify-center items-center">
                                  <h4 className='font-bold text-red-700'>{situation.typeSit}</h4>
                                </div>
                              </td>
                            </tr>
                          </thead>
                          <tbody>

                            <table ref={situationRef} className='table-auto'>
                              <thead>

                              </thead>
                              <tbody>

                                <tr>
                                  <td colSpan="3">Solde Départ</td>
                                  <td className='font-bold flex justify-end'><NumberFormat valeur={situation.soldeDepart} /></td>
                                </tr>
                                <tr>
                                  <td colSpan="3">Total entrées</td>
                                  <td className='font-bold flex justify-end'><NumberFormat valeur={situation.totalEntree} /></td>
                                </tr>
                                <tr>
                                  <td colSpan="3">Total Sorties</td>
                                  <td className='font-bold flex justify-end'><NumberFormat valeur={situation.totalSortie} /></td>
                                </tr>
                                <tr>
                                  <td colSpan="3">Alimentation Caisses</td>
                                  <td className='font-bold flex justify-end'><NumberFormat valeur={situation.totalAlimentation} /></td>
                                </tr>
                                <tr>
                                  <td colSpan="3">Déchargement Caisses</td>
                                  <td className='font-bold flex justify-end'><NumberFormat valeur={situation.totalDechargement} /></td>
                                </tr>
                                <tr>
                                  <td colSpan="3">Solde Restant</td>
                                  <td className='font-bold flex justify-end'><NumberFormat valeur={situation.soldeRestant} /></td>
                                </tr>
                              </tbody>
                            </table>
                          </tbody>
                        </table>
                      </div>

                    </div>
                  </>
                }

              </>
              :
              <></>

            }


          </>

        }


      </div>
    </>
  )
}

export default Situation