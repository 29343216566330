import React from 'react'
import { BsFillBuildingFill } from 'react-icons/bs'
import { FaRegMoneyBillAlt } from 'react-icons/fa'
import { FiShoppingCart, FiUsers } from 'react-icons/fi'
import { NavLink } from 'react-router-dom'
import { TbMath } from "react-icons/tb";
import { useStateContext } from '../../contexts/ContextProvider'
import { LuFileSpreadsheet } from 'react-icons/lu'
import { PiStudentBold } from 'react-icons/pi'
import { FaFileLines, FaMoneyBill1Wave, FaRegCalendarDays } from 'react-icons/fa6'
import { BiArrowToTop } from "react-icons/bi";
import { MdManageHistory } from "react-icons/md";
import { CiInboxOut } from "react-icons/ci";
import { FaBuildingColumns } from "react-icons/fa6";
import { GiCash } from "react-icons/gi";
import { IoLogInSharp } from "react-icons/io5";
import { MdTransitEnterexit } from "react-icons/md";

const CaissierCentraleRole = () => {
    const activeLink = 'flex items-center gap-5 pl-4 pt-3  bp-3 rounded-lg text-white bg-blue-600 text-md m-2';
    const normalLink = 'flex items-center gap-5 pl-4 pt-3  bp-3 rounded-lg  text-md text-gray-700 dark:text-gray-200 dark:hover:text-black hover:bg-light-gray m-2';
    const { setActiveMenu, screenSize, currentColor } = useStateContext();


    const handleCloseSidebar = () => {
        if (screenSize <= 900) {
            setActiveMenu(false);

        }
    }
    return (
        < >

            <div>
                <NavLink to={`/entrees`} key='entrees' onClick={handleCloseSidebar}
                    className={({ isActive }) =>
                        isActive ? activeLink : normalLink
                    } style={({ isActive }) => isActive ? { backgroundColor: `${currentColor}` } : { backgroundColor: 'white' }}>
                    <IoLogInSharp />
                    <span className='capitalize mb-2'>
                        Entrees
                    </span>
                </NavLink>
                <NavLink to={`/alimentations`} key='alimentations' onClick={handleCloseSidebar}
                    className={({ isActive }) =>
                        isActive ? activeLink : normalLink
                    } style={({ isActive }) => isActive ? { backgroundColor: `${currentColor}` } : { backgroundColor: 'white' }}>
                    <BiArrowToTop />
                    <span className='capitalize mb-2'>
                        Alimentations
                    </span>
                </NavLink>
                <NavLink to={`/dechargements`} key='dechargements' onClick={handleCloseSidebar}
                    className={({ isActive }) =>
                        isActive ? activeLink : normalLink
                    } style={({ isActive }) => isActive ? { backgroundColor: `${currentColor}` } : { backgroundColor: 'white' }}>
                    <MdTransitEnterexit />
                    <span className='capitalize mb-2'>
                        Dechargement
                    </span>
                </NavLink>
                
                <NavLink to={`/sorties`} key='sorties' onClick={handleCloseSidebar}
                    className={({ isActive }) =>
                        isActive ? activeLink : normalLink
                    } style={({ isActive }) => isActive ? { backgroundColor: `${currentColor}` } : { backgroundColor: 'white' }}>
                    <CiInboxOut />
                    <span className='capitalize mb-2'>
                        Sorties
                    </span>
                </NavLink>

                <NavLink to={`/situations`} key='situations' onClick={handleCloseSidebar}
                    className={({ isActive }) =>
                        isActive ? activeLink : normalLink
                    } style={({ isActive }) => isActive ? { backgroundColor: `${currentColor}` } : { backgroundColor: 'white' }}>
                    <FaRegCalendarDays />
                    <span className='capitalize mb-2'>
                        Situations
                    </span>
                </NavLink>
                <NavLink to={`/documents`} key='documents' onClick={handleCloseSidebar}
                    className={({ isActive }) =>
                        isActive ? activeLink : normalLink
                    } style={({ isActive }) => isActive ? { backgroundColor: `${currentColor}` } : { backgroundColor: 'white' }}>
                    <FaFileLines />
                    <span className='capitalize mb-2'>
                        Documents
                    </span>
                </NavLink>
            </div>
        </>

    )
}

export default CaissierCentraleRole