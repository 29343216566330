import React, { useState } from 'react';
import { Button, Header, Input } from '../../components';
import Loading from '../../components/Loading';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useStateContext } from '../../contexts/ContextProvider';
import axiosClient from '../../axios-client';
import Alert from '../../components/Alert';
import { toast } from 'react-toastify';
import { GiCancel } from 'react-icons/gi';
import { IoIosSave } from 'react-icons/io';
import Select from 'react-select';

const AddPrivilege = ({ onClose }) => {
    const [loading, setLoading] = useState(false);

    const { token, setToken } = useStateContext();
    const [errors, setErrors] = useState(null);
    const [usersTop, setUsersTop] = useState([]);
    const [selectedUserTop, setSelectedUserTop] = useState(null);
    const [inputValueUserTop, setValueUserTop] = useState('');
    const [usersDown, setUsersDown] = useState([]);
    const [selectedUserDown, setSelectedUserDown] = useState(null);
    const [inputValueUserDown, setValueUserDown] = useState('');
    const [bon, setBon] = useState(null);
    const [facture, setFacture] = useState(null);

    const [privilege, setPrivilege] = useState({
       userTop:'',
       usersDown:'',
       type:''
    });

    useEffect(() => {
        getUsers();
    }, []);

    const handleInputChangeUserTop = value => {
        setValueUserTop(value);
    };

    // handle selection


    // handle selection
    const handleChangeUserTop = value => {
        setSelectedUserTop(value);
        setPrivilege({ ...privilege, userTop: value.id });

    }
    const handleInputChangeUserDown = value => {
        setValueUserDown(value);
    };

    // handle selection


    // handle selection
    const handleChangeUserDown = value => {
        setSelectedUserDown(value);
        setPrivilege({ ...privilege, userDown: value.id });

    }
    
    const navigate = useNavigate();
    const getUsers = () => {
        axiosClient.get('/users')
            .then((data) => {
                setUsersTop(data.data.users);
                setUsersDown(data.data.users);
            }).catch(err => {
                const response = err.response;
                if (response.status === 401) {
                    setToken(null);
                }
                else {
                    if (response.status === 422) {
                        setErrors(response.data.errors);
                        setLoading(false);
                        toast.error('Une erreur est surveue!', {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        });
                    } else {
                        if (response.status === 403) {
                            navigate('/403');
                        } else {
                            if (response.status === 404) {
                                navigate('/404');
                            }
                            else {
                                navigate('/500');
                            }
                        }
                    }

                }
            })
    }
    const onSubmit = () => {

        setErrors(null);
        setLoading(true);
        
        axiosClient.post('/privilege/save', privilege)
            .then((response) => {
                setLoading(false);
                setErrors(null);
                toast.success('Opération effectuée avec succès!', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });

                onClose();
            }).catch(err => {
                const response = err.response;
                if (response.status === 401) {
                    setToken(null);
                }
                else {
                    if (response.status === 422) {
                        setErrors(response.data.errors);
                        setLoading(false);
                        toast.error('Merci de verifier les informations obligatoires!', {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        });
                    } else {
                        if (response.status === 403) {
                            navigate('/403');
                        } else {
                            if (response.status === 404) {
                                navigate('/404');
                            }
                            else {
                                navigate('/500');
                            }
                        }
                    }

                }
            })
    }



    return (
        <>
            <div className=' fixed inset-0 h-full bg-black bg-opacity-25 backdrop-blur-sm  '>
                <div className='h-screen flex justify-center items-center'>
                    <div className='flex-col max-h-screen overflow-y-auto bg-white lg:p-10 xl:p-10  p-2 md:p-10 w-auto md:w-1/2 lg:w-1/2 xl:w-1/2 rounded-xl'>
                        <div className=''>
                            <Header title="Ajouter un privilege" />
                        </div>
                        {errors &&
                            Object.keys(errors).map(key => (
                                <Alert key={key} message={errors[key][0]} />
                            ))
                        }
                        <>
                            <div className='flex flex-wrap md:flex-nowrap lg:flex-nowrap xl:flex-nowrap gap-3'>
                                <div className='flex-col w-full justify-evenly '>
                                    <div className="flex-col w-full">
                                        <label className="block text-sm font-medium leading-6 text-gray-900">Utilisateur Sup <span className='text-red-500'> *</span></label>
                                        <Select className='mt-1 outline-1 ' placeholder="UserTop" cacheOptions defaultOptions value={selectedUserTop} getOptionLabel={e => e.nom} getOptionValue={e => e.id} options={usersTop} onInputChange={handleInputChangeUserTop} onChange={handleChangeUserTop} />
                                    </div>
                                    <div className="flex-col w-full">
                                        <label className="block text-sm font-medium leading-6 text-gray-900">Utilisateur Inf <span className='text-red-500'> *</span></label>
                                        <Select className='mt-1 outline-1 ' placeholder="UserDown" cacheOptions defaultOptions value={selectedUserDown} getOptionLabel={e => e.nom} getOptionValue={e => e.id} options={usersDown} onInputChange={handleInputChangeUserDown} onChange={handleChangeUserDown} />
                                    </div>
                                    <div className='mr-2'>
                                        <Input label="Type" type="text" value={privilege.type} onChange={ev => setPrivilege({ ...privilege, type: ev.target.value })} />
                                    </div>
                                </div>
                            </div>
                            <div className='flex  justify-end items-end'>
                                {loading ? <Loading /> :
                                    <div className=' flex gap-3 m-2  justify-between'>
                                        <Button icon={<IoIosSave />} onClick={onSubmit} color="ml-1 bg-green-500" textColor="text-white" text="Enregistrer" />
                                        <Button icon={<GiCancel />} onClick={() => onClose()} color="bg-red-500" textColor="text-white" text="Fermer" />
                                    </div>
                                }
                            </div>
                        </>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AddPrivilege