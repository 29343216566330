import React, { useEffect, useState } from 'react'
import { Button, Header } from '../../components'
import { BsPlusCircleFill, BsTrash } from 'react-icons/bs';
import { Link, useNavigate } from 'react-router-dom';
import axiosClient from '../../axios-client';
import Loading from '../../components/Loading';
import { useStateContext } from '../../contexts/ContextProvider';
import { AiTwotoneEdit } from 'react-icons/ai';
import ActionButton from '../../components/ActionButton';
import AddCoefficient from './AddCoefficient';
import EditCoefficient from './EditCoefficient';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';

const Coefficients = () => {
    const navigate = useNavigate();
    const [classes, setClasses] = useState(null);
    const [loading, setLoading] = useState(false);
    const { setToken } = useStateContext();
    const [showAdd, setShowAdd] = useState(false);
    const [showEdit, setShowEdit] = useState(false);
    const [selectedCoeff, setSelectedCoeff] = useState(null);
    const getCoefficients = () => {
        setLoading(true);
        axiosClient.get('/coefficients')
            .then((data) => {
                setClasses(data.data.coefficients);
                setLoading(false);

            }).catch(err => {
                const response = err.response;
                if (err.response.status === 401) {
                    setToken(null);
                } else {
                    if (response.status === 401) {
                        setToken(null);
                    }
                    else {
                        if (response.status === 403) {
                            navigate('/403');
                        } else {
                            if (response.status === 404) {
                                navigate('/404');
                            }
                            else {
                                navigate('/500');
                            }
                        }
                    }
                }

            })
    }

    const supprimer = (coeff) => {
        Swal.fire({
            title: "Confirmation",
            text: "Etes vous sûre de supprimer cette element ?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#2196F3",
            cancelButtonColor: "#d33",
            confirmButtonText: "Oui!",
            cancelButtonText: "Non!"
        }).then((result) => {
            if (result.isConfirmed) {
                axiosClient.delete('/coefficient/' + coeff.id)
                    .then(response => {
                        toast.success('Opération effectuée avec succès!', {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        });
                        getCoefficients();
                    }).catch(err => {
                        const response = err.response;
                        if (err.response.status === 401) {
                            setToken(null);
                        } else {
                            if (response.status === 401) {
                                setToken(null);
                            }
                            else {
                                if (response.status === 403) {
                                    navigate('/403');
                                } else {
                                    if (response.status === 404) {
                                        navigate('/404');
                                    }
                                    else {
                                        navigate('/500');
                                    }
                                }
                            }
                        }

                    })
            }
        });
    }

    const closeShowAdd=()=>{
        setShowAdd(false);
        setSelectedCoeff(null);
        getCoefficients();
    }
    const closeShowEdit=()=>{
        setShowEdit(false);
        setSelectedCoeff(null);
        getCoefficients();
    }

    useEffect(() => {
        getCoefficients();
    }, []);
    return (
        <>
            <Header category='Page' title='Coefficients' />
            <div className='flex-col '>
                <div className='items-end'>
                    <Button onClick={()=>setShowAdd(true)} color="bg-blue-500" icon={<BsPlusCircleFill />} size="20" text="Ajouter" textColor="text-white" />
                </div>
                <div className='mt-3 w-full overflow-x-auto'>
                    {loading ? <Loading /> :
                        (
                            <table className='table-auto'>
                                <thead>
                                    <tr>
                                        <th>Groupe</th>
                                        <th>Matiere</th>
                                        <th>Coefficients</th>
                                        <th>Note Max</th>
                                        <th>Ordre</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {classes && classes.map(o => (
                                        <tr key={o.id}>
                                            <td>{o.groupe}</td>
                                            <td>{o.matiere}</td>
                                            <td>{o.coefficient}</td>
                                            <td>{o.noteMax}</td>
                                            <td>{o.ordre}</td>
                                            <td>
                                                <div className="flex justify-evenly">
                                                    <ActionButton onClick={()=>{
                                                        setSelectedCoeff(o);
                                                        setShowEdit(true);

                                                    }} color="bg-green-500" icon={<AiTwotoneEdit />} textColor="text-white" title="Modifier" />
                                                    <ActionButton onClick={()=>{supprimer(o)}} color="bg-red-500" icon={<BsTrash />} textColor="text-white" title="Supprimer" />
                                                </div>
                                            </td>
                                        </tr>
                                    ))

                                    }
                                </tbody>
                            </table>
                        )

                    }

                    
                </div>
                {
                    showAdd ? <AddCoefficient onClose={closeShowAdd}/>:<></>
                }
                {
                    showEdit ? <EditCoefficient ucoefficient={selectedCoeff} onClose={closeShowEdit}/>:<></>
                }
            </div>
        </>
    )
}

export default Coefficients