import React, { useState } from 'react';
import { Button, Header, Input } from '../../components';
import Loading from '../../components/Loading';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useStateContext } from '../../contexts/ContextProvider';
import axiosClient from '../../axios-client';
import Alert from '../../components/Alert';
import { toast } from 'react-toastify';
import { GiCancel } from 'react-icons/gi';
import { IoIosSave } from 'react-icons/io';
import Select from 'react-select';

const EditCaisse = ({ editedCaisse, onClose }) => {
    const [loading, setLoading] = useState(false);

    const { token, setToken } = useStateContext();
    const [errors, setErrors] = useState(null);
    

    const [caisse, setCaisse] = useState({
        id:editedCaisse.id,
        nom: editedCaisse.nom,
        plafondDepense:editedCaisse.plafondDepense,
        plafondAlimentation:editedCaisse.plafondAlimentation,
        soldeMax:editedCaisse.soldeMax,
        soldeMin:editedCaisse.soldeMin
    });
    

    // handle selection


    // handle selection
    
    useEffect(() => {
        
    }, []);


    const navigate = useNavigate();

    const onSubmit = () => {

        setErrors(null);
        setLoading(true);

        axiosClient.put('/caisse/update', caisse)
            .then((response) => {
                setLoading(false);
                setErrors(null);
                toast.success('Opération effectuée avec succès!', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });

                onClose();
            }).catch(err => {
                const response = err.response;
                if (response.status === 401) {
                    setToken(null);
                }
                else {
                    if (response.status === 422) {
                        setErrors(response.data.errors);
                        setLoading(false);
                        toast.error('Une erreur est survenue!', {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        });
                    } else {
                        if (response.status === 403) {
                            navigate('/403');
                        } else {
                            if (response.status === 404) {
                                navigate('/404');
                            }
                            else {
                                navigate('/500');
                            }
                        }
                    }

                }
            })
    }


    return (
        <>
            <div className=' fixed inset-0 h-full bg-black bg-opacity-25 backdrop-blur-sm  '>
                <div className='h-screen flex justify-center items-center'>
                    <div className='flex-col bg-white lg:p-10 xl:p-10  p-2 md:p-10 w-auto rounded-xl'>
                        <div className=''>
                            <Header title="Modifier caisse" />
                        </div>
                        {errors &&
                            Object.keys(errors).map(key => (
                                <Alert key={key} message={errors[key][0]} />
                            ))
                        }

                        <>
                            <div className='flex flex-wrap md:flex-nowrap lg:flex-nowrap xl:flex-nowrap gap-3'>
                                <div className='flex-col w-full justify-evenly '>
                                    <div className='mr-2'>
                                        <Input label="Nom" type="text" value={caisse.nom} onChange={ev => setCaisse({ ...caisse, nom: ev.target.value })} required="required" />
                                    </div>
                                    <div className='mr-2'>
                                        <Input label="Plafond, dépense mensuelle" type="text" value={caisse.plafondDepense} onChange={ev => setCaisse({ ...caisse, plafondDepense: ev.target.value })} required="required" />
                                    </div>
                                    <div className='mr-2'>
                                        <Input label="Plafond d'alimentation quotidien" type="text" value={caisse.plafondAlimentation} onChange={ev => setCaisse({ ...caisse, plafondAlimentation: ev.target.value })} required="required" />
                                    </div>
                                    <div className='mr-2'>
                                        <Input label="Solde Max" type="text" value={caisse.soldeMax} onChange={ev => setCaisse({ ...caisse, soldeMax: ev.target.value })} required="required" />
                                    </div>
                                    <div className='mr-2'>
                                        <Input label="Solde min" type="text" value={caisse.soldeMin} onChange={ev => setCaisse({ ...caisse, soldeMin: ev.target.value })} required="required" />
                                    </div>
                                </div>

                            </div>
                        </>


                        <div className='flex  justify-end items-end'>
                            {loading ? <Loading /> :
                                <div className=' flex gap-3 m-2  justify-between'>
                                    <Button icon={<IoIosSave />} onClick={onSubmit} color="ml-1 bg-green-500" textColor="text-white" text="Enregistrer" />
                                    <Button icon={<GiCancel />} onClick={() => onClose()} color="bg-red-500" textColor="text-white" text="Fermer" />
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default EditCaisse