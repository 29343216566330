import React from 'react'
import { BsFillBuildingFill } from 'react-icons/bs';
import { NavLink } from 'react-router-dom';
import { useStateContext } from '../../contexts/ContextProvider';
import { PiStudentBold } from "react-icons/pi";
import { LuFileSpreadsheet } from "react-icons/lu";
import { FaFileLines, FaRegCalendarDays } from "react-icons/fa6";
import { FiShoppingCart } from 'react-icons/fi';
import { BiArrowToTop } from "react-icons/bi";
import { CiInboxOut } from "react-icons/ci";
import { MdTransitEnterexit } from 'react-icons/md';
import { FaMoneyBill1Wave } from "react-icons/fa6";

const DistRole = () => {
    const activeLink = 'flex items-center gap-5 pl-4 pt-3  bp-3 rounded-lg text-white bg-blue-600 text-md m-2';
    const normalLink = 'flex items-center gap-5 pl-4 pt-3  bp-3 rounded-lg  text-md text-gray-700 dark:text-gray-200 dark:hover:text-black hover:bg-light-gray m-2';
    const { setActiveMenu, screenSize, currentColor } = useStateContext();


    const handleCloseSidebar = () => {
        if (screenSize <= 900) {
            setActiveMenu(false);

        }
    }
    return (
        <div >

            <div>
                <NavLink to={`/depenses`} key='despenses' onClick={handleCloseSidebar}
                    className={({ isActive }) =>
                        isActive ? activeLink : normalLink
                    } style={({ isActive }) => isActive ? { backgroundColor: `${currentColor}` } : { backgroundColor: 'white' }}>
                    <FiShoppingCart />
                    <span className='capitalize mb-2'>
                        Dépenses
                    </span>
                </NavLink>
                <NavLink to={`/paiements`} key='paiements' onClick={handleCloseSidebar}
                    className={({ isActive }) =>
                        isActive ? activeLink : normalLink
                    } style={({ isActive }) => isActive ? { backgroundColor: `${currentColor}` } : { backgroundColor: 'white' }}>
                    <FaMoneyBill1Wave />
                    <span className='capitalize mb-2'>
                        Paiements
                    </span>
                </NavLink>
                <NavLink to={`/situationcaisse`} key='situations' onClick={handleCloseSidebar}
                    className={({ isActive }) =>
                        isActive ? activeLink : normalLink
                    } style={({ isActive }) => isActive ? { backgroundColor: `${currentColor}` } : { backgroundColor: 'white' }}>
                    <FaRegCalendarDays />
                    <span className='capitalize mb-2'>
                        Situations
                    </span>
                </NavLink>   
            </div>
        </div>

    )
}

export default DistRole