import React, { useEffect, useRef, useState } from 'react'
import { Button, Header, Input } from '../../components'
import Alert from '../../components/Alert'
import { IoIosSearch } from 'react-icons/io';

import { useNavigate } from 'react-router-dom';
import axiosClient from '../../axios-client';
import { useStateContext } from '../../contexts/ContextProvider';
import { toast } from 'react-toastify';
import { DownloadTableExcel } from 'react-export-table-to-excel';
import { FaBan, FaCheck, FaRegFileExcel } from 'react-icons/fa6';
import { BsPlusCircleFill, BsTrash } from 'react-icons/bs';
import Loading from '../../components/Loading';
import AddPaiement from './AddPaiement';
import ActionButton from '../../components/ActionButton';
import Swal from 'sweetalert2';
import Select from 'react-select';
import NumberFormat from '../../components/NumberFormat';
import EditPaiement from './EditPaiement';
import { FaCircle, FaEdit } from 'react-icons/fa';
import AddDistPaiement from './AddDistPaiement';


const Paiements = () => {
    const [errors, setErrors] = useState(null);

    const [loading, setLoading] = useState(false);
    const [paiements, setPaiements] = useState([]);
    const [showAddPaiement, setShowAddPaiement] = useState(false);
    const { token, setToken, user } = useStateContext();
    const [services, setServices] = useState([]);
    const [selectedService, setSelectedService] = useState(null);
    const [inputValueService, setValueService] = useState('');
    const [caisses, setCaisses] = useState([]);
    const [selectedCaisse, setSelectedCaisse] = useState(null);
    const [inputValueCaisse, setValueCaisse] = useState('');
    const [selectedPaiement, setSelectedPaiement] = useState(null);
    const [showEditPaiement, setShowEditPaiement] = useState(false);
    const [showAddDistPaiement, setShowAddDistPaiement] = useState(false);
    const tableRef = useRef();
    const [req, setReq] = useState({
        DateDebut: '',
        DateFin: '',
        caisse: '',
        service: '',
    });
    const navigate = useNavigate();

    const supprimer = (paiement) => {
        Swal.fire({
            title: "Confirmation",
            text: "Etes vous sûre de supprimer cette element ?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#2196F3",
            cancelButtonColor: "#d33",
            confirmButtonText: "Oui!",
            cancelButtonText: "Non!"
        }).then((result) => {
            if (result.isConfirmed) {
                axiosClient.delete('/paiement/' + paiement.id)
                    .then(response => {
                        toast.success('Opération effectuée avec succès!', {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        });
                        getPaiements();
                    }).catch(err => {
                        const response = err.response;
                        if (response.status === 401) {
                            setToken(null);
                        }
                        else {
                            if (response.status === 422) {
                                setErrors(response.data.errors);
                                setLoading(false);
                                toast.error('Une erreur est survenue!', {
                                    position: "top-right",
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    theme: "light",
                                });
                            } else {
                                if (response.status === 403) {
                                    navigate('/403');
                                } else {
                                    if (response.status === 404) {
                                        navigate('/404');
                                    }
                                    else {
                                        navigate('/500');
                                    }
                                }
                            }
        
                        }
                    })
            }
        });
    }

    useEffect(() => {
        if (user.role != null && (user.role.name === 'admin' || user.role.name === 'controleur'  || user.role.name === 'dist')) {
            getServices();
            getCaisses();
        }
    }, []);

    const handleInputChangeService = value => {
        setValueService(value);
    };

    // handle selection


    // handle selection
    const handleChangeService = value => {
        setSelectedService(value);
        setReq({ ...req, service: value.id });

    }

    const handleInputChangeCaisse = value => {
        setValueCaisse(value);
    };


    // handle selection

    // handle selection


    const handleChangeCaisse = value => {
        setSelectedCaisse(value);
        setReq({ ...req, caisse: value.id });

    }

    const getServices = () => {
        axiosClient.get('/services')
            .then((data) => {
                setServices(data.data.services);
            }).catch(err => {
                const response = err.response;
                if (response.status === 401) {
                    setToken(null);
                }
                else {
                    if (response.status === 422) {
                        setErrors(response.data.errors);
                        setLoading(false);
                        toast.error('Une Erreur est survenue', {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        });
                    } else {
                        if (response.status === 403) {
                            navigate('/403');
                        } else {
                            if (response.status === 404) {
                                navigate('/404');
                            }
                            else {
                                navigate('/500');
                            }
                        }
                    }

                }
            })
    }
    const getCaisses = () => {
        axiosClient.get('/caisses')
            .then((data) => {
                setCaisses(data.data.caisses);
            }).catch(err => {
                const response = err.response;
                if (response.status === 401) {
                    setToken(null);
                }
                else {
                    if (response.status === 422) {
                        setErrors(response.data.errors);
                        setLoading(false);
                        toast.error('Une erreur est survenue!', {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        });
                    } else {
                        if (response.status === 403) {
                            navigate('/403');
                        } else {
                            if (response.status === 404) {
                                navigate('/404');
                            }
                            else {
                                navigate('/500');
                            }
                        }
                    }

                }
            })
    }

    const valider = (paiement) => {
        axiosClient.put('/validerPaiement/' + paiement.id)
            .then((data) => {
                toast.success('Opération effectuée avec succès!', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }).catch(err => {
                const response = err.response;
                if (response.status === 401) {
                    setToken(null);
                }
                else {
                    if (response.status === 422) {
                        setErrors(response.data.errors);
                        setLoading(false);
                        toast.error('Une erreur est survenue!', {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        });
                    } else {
                        if (response.status === 403) {
                            navigate('/403');
                        } else {
                            if (response.status === 404) {
                                navigate('/404');
                            }
                            else {
                                navigate('/500');
                            }
                        }
                    }

                }
            })
    }
    const annuler = (paiement) => {
        axiosClient.put('/annulerPaiement/' + paiement.id)
            .then((data) => {
                toast.success('Opération effectuée avec succès!', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }).catch(err => {
                const response = err.response;
                if (response.status === 401) {
                    setToken(null);
                }
                else {
                    if (response.status === 422) {
                        setErrors(response.data.errors);
                        setLoading(false);
                        toast.error('Une erreur est survenue!', {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        });
                    } else {
                        if (response.status === 403) {
                            navigate('/403');
                        } else {
                            if (response.status === 404) {
                                navigate('/404');
                            }
                            else {
                                navigate('/500');
                            }
                        }
                    }

                }
            })
    }

    const validerMasse = (paiement) => {
        axiosClient.post('/validerMasse', req)
            .then((data) => {
                toast.success('Opération effectuée avec succès!', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }).catch(err => {
                const response = err.response;
                if (response.status === 401) {
                    setToken(null);
                }
                else {
                    if (response.status === 422) {
                        setErrors(response.data.errors);
                        setLoading(false);
                        toast.error('Une erreur est survenue!', {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        });
                    } else {
                        if (response.status === 403) {
                            navigate('/403');
                        } else {
                            if (response.status === 404) {
                                navigate('/404');
                            }
                            else {
                                navigate('/500');
                            }
                        }
                    }

                }
            })
    }
    const annulerMasse = (paiement) => {
        axiosClient.post('/annulerMasse', req)
            .then((data) => {
                toast.success('Opération effectuée avec succès!', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }).catch(err => {
                const response = err.response;
                if (response.status === 401) {
                    setToken(null);
                }
                else {
                    if (response.status === 422) {
                        setErrors(response.data.errors);
                        setLoading(false);
                        toast.error('Une erreur est survenue!', {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        });
                    } else {
                        if (response.status === 403) {
                            navigate('/403');
                        } else {
                            if (response.status === 404) {
                                navigate('/404');
                            }
                            else {
                                navigate('/500');
                            }
                        }
                    }

                }
            })
    }

    const closeAddPaiement = () => {
        setShowAddPaiement(false);
    }

    const closeEditPaiement = () => {
        setShowEditPaiement(false);
        setSelectedPaiement(null);
    }

    const addDist=()=>{
        if(selectedCaisse===null){
            Swal.fire({
                title: "Alert",
                text: "Veillez sélectionner une caisse!",
                icon: "warning",
                confirmButtonColor: "#2196F3",
                confirmButtonText: "OK!",
            });
        }else{
            setShowAddDistPaiement(true);
        }
    }
    const closeAddDistPaiement = () => {
        setShowAddDistPaiement(false);

    }

    const getPaiements = () => {
        setLoading(true);
        axiosClient.get('/paiements?DateDebut=' + req.DateDebut + '&DateFin=' + req.DateFin + '&caisse=' + req.caisse + '&service=' + req.service)
            .then(response => {
                setPaiements(response.data.paiements);
                setLoading(false);
                setErrors(null);
            }).catch(err => {
                const response = err.response;
                if (response.status === 401) {
                    setToken(null);
                }
                else {
                    if (response.status === 422) {
                        setErrors(response.data.errors);
                        setLoading(false);
                        toast.error('Une eurreur est survenue!', {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        });
                    } else {
                        if (response.status === 403) {
                            navigate('/403');
                        } else {
                            if (response.status === 404) {
                                navigate('/404');
                            }
                            else {
                                navigate('/500');
                            }
                        }
                    }

                }
            })
    }

    return (
        <>
            <Header title="Paiements" />
            {errors &&
                Object.keys(errors).map(key => (
                    <Alert key={key} message={errors[key][0]} />
                ))
            }
            <div className='flex gap-2 w-full flex-wrap md:flex-nowrap lg:flex-nowrap xl:flex-nowrap justify-between items-center'>
                <Input label="Date debut" type="date" value={req.DateDebut} onChange={ev => setReq({ ...req, DateDebut: ev.target.value })} required="required" />
                <Input label="Date fin" type="date" value={req.DateFin} onChange={ev => setReq({ ...req, DateFin: ev.target.value })} required="required" />
                {user.role && (user.role.name === 'admin' || user.role.name === 'controleur'  || user.role.name === 'dist') ?
                    <>
                        <div className="flex-col w-full">
                            <label className="block text-sm font-medium leading-6 text-gray-900">Service <span className='text-red-500'> *</span></label>
                            <Select className='mt-1 outline-1 ' placeholder="Service" cacheOptions defaultOptions value={selectedService} getOptionLabel={e => e.libelle} getOptionValue={e => e.id} options={services} onInputChange={handleInputChangeService} onChange={handleChangeService} />
                        </div>
                        <div className="flex-col w-full">
                            <label className="block text-sm font-medium leading-6 text-gray-900">Caisse <span className='text-red-500'> *</span></label>
                            <Select className='mt-1 outline-1 ' placeholder="Caisse" cacheOptions defaultOptions value={selectedCaisse} getOptionLabel={e => e.nom} getOptionValue={e => e.id} options={caisses} onInputChange={handleInputChangeCaisse} onChange={handleChangeCaisse} />
                        </div>
                    </>
                    :
                    <></>
                }
                <div className=' flex gap-2 mt-8'>
                    <Button onClick={() => getPaiements()} color="bg-yellow-500" icon={<IoIosSearch />} size="20" text="Chercher" textColor="text-white" />
                    <DownloadTableExcel
                        filename="Paiements"
                        sheet="paiements"
                        currentTableRef={tableRef.current}
                    >
                        <Button color="bg-green-500" icon={<FaRegFileExcel />} size="20" text="Exporter" textColor="text-white" />

                    </DownloadTableExcel>
                </div>
            </div>
            {user.role && user.role.name === 'admin' ?
                <div className="mt-4 flex gap-2 w-full flex-wrap md:flex-nowrap lg:flex-nowrap xl:flex-nowrap justify-end items-end">
                    <ActionButton onClick={() => { validerMasse() }} color="bg-green-500" icon={<FaCheck />} textColor="text-white" title="Valider tous" />

                    <ActionButton onClick={() => { annulerMasse() }} color="bg-yellow-500" icon={<FaBan />} textColor="text-white" title="Annuler tous" />
                </div>
                : <></>
            }
            {
                user.role && user.role.name === 'caissier' ? <div className="flex my-4">
                    <Button onClick={() => setShowAddPaiement(true)} color="bg-blue-500" icon={<BsPlusCircleFill />} size="20" text="Ajouter" textColor="text-white" />
                </div>
                    : <></>
            }
            {
                user.role && (user.role.name === 'admin' || user.role.name === 'controleur'  || user.role.name === 'dist') ? <div className="flex my-4">
                    <Button onClick={() => addDist()} color="bg-blue-500" icon={<BsPlusCircleFill />} size="20" text="Ajouter" textColor="text-white" />
                </div>
                    : <></>
            }
            <div className="flex my-2">
                <h1 className='text-red-500 font-bold'>Total: <NumberFormat valeur={paiements.reduce((n, { montant }) => n + parseFloat(montant), 0)} /> MRU</h1>
            </div>
            <div className='flex mt-3 overflow-auto'>
                <table ref={tableRef} className='table-auto' >
                    <thead>
                        <tr>
                            <th>Date</th>
                            <th>Caisse</th>
                            <th>Service</th>
                            <th>Nombre</th>
                            <th>Montant</th>
                            <th>Opérateur</th>
                            <th>État</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    {loading ? <tbody><tr className='content-center items-center'><td className='content-center items-center' colSpan="6"><Loading /></td></tr></tbody> :
                        <tbody>
                            {paiements.map(o => (
                                <tr>
                                    <td>{o.datePaiement}</td>
                                    <td>{o.caisse}</td>
                                    <td>{o.service.libelle}</td>
                                    <td>{o.nombre}</td>
                                    <td>{o.montant}</td>
                                    <td>{o.operateur}</td>
                                    {
                                        o.valide === false ?
                                            <div className='flex justify-center items-center text-lg text-orange-500'>
                                                <FaCircle />
                                            </div>
                                            :
                                            <div className='flex justify-center content-center items-center text-lg text-lime-500'>
                                                <FaCircle />
                                            </div>
                                    }
                                    <td>
                                        <div className="flex gap-2">
                                            {user.role && (user.role.name === 'admin') ?
                                                <>
                                                    {o.valide === false ?
                                                        <ActionButton onClick={() => {
                                                            valider(o);
                                                        }} color="bg-green-500" icon={<FaCheck />} textColor="text-white" title="Valider" />
                                                        :
                                                        <ActionButton onClick={() => {
                                                            annuler(o);
                                                        }} color="bg-yellow-500" icon={<FaBan />} textColor="text-white" title="Annuler" />
                                                    }
                                                    <ActionButton onClick={() => {
                                                        setSelectedPaiement(o);
                                                        setShowEditPaiement(true);
                                                    }} color="bg-blue-500" icon={<FaEdit />} textColor="text-white" title="Modifier" />
                                                </>
                                                :
                                                <></>
                                            }
                                            {user.role && (user.role.name === 'controleur') ?
                                                <>
                                                    {o.valide === false ?
                                                        <>
                                                            <ActionButton onClick={() => {
                                                                valider(o);
                                                            }} color="bg-green-500" icon={<FaCheck />} textColor="text-white" title="Valider" />
                                                            <ActionButton onClick={() => {
                                                                setSelectedPaiement(o);
                                                                setShowEditPaiement(true);
                                                            }} color="bg-blue-500" icon={<FaEdit />} textColor="text-white" title="Modifier" />
                                                        </>
                                                        :
                                                        <></>
                                                    }
                                                    <ActionButton onClick={() => { supprimer(o) }} color="bg-red-500" icon={<BsTrash />} textColor="text-white" title="Supprimer" />
                                                </>
                                                :
                                                <></>
                                            }
                                            {user.role && (user.role.name === 'admin' || user.role.name === 'caissier' || user.role.name === 'dist') ?
                                                <ActionButton onClick={() => { supprimer(o) }} color="bg-red-500" icon={<BsTrash />} textColor="text-white" title="Supprimer" />
                                                : <></>
                                            }
                                        </div>
                                    </td>
                                </tr>
                            ))

                            }
                        </tbody>
                    }

                </table>
            </div>
            {
                showAddPaiement ? <AddPaiement onClose={closeAddPaiement} /> : <></>
            }
            {
                showEditPaiement ? <EditPaiement editedPaiement={selectedPaiement} onClose={closeEditPaiement} /> : <></>
            }
            {
                showAddDistPaiement ? <AddDistPaiement cai={selectedCaisse} onClose={closeAddDistPaiement} /> : <></>
            }

        </>
    )
}

export default Paiements