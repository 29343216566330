import React, { useEffect, useRef, useState } from 'react'
import { Button, Header, Input } from '../../components'
import Alert from '../../components/Alert'
import { IoIosSearch } from 'react-icons/io';

import { useNavigate } from 'react-router-dom';
import axiosClient from '../../axios-client';
import { useStateContext } from '../../contexts/ContextProvider';
import { toast } from 'react-toastify';
import Loading from '../../components/Loading';
import ActionButton from '../../components/ActionButton';
import Swal from 'sweetalert2';
import { FaEdit } from 'react-icons/fa';
import NumberFormat from '../../components/NumberFormat';
import EditCaisse from './EditCaisse';


const Caisses = () => {
    const [errors, setErrors] = useState(null);

    const [loading, setLoading] = useState(false);
    const [caisses, setCaisses] = useState([]);
    const [showEditCaisse,setShowEditCaisse]=useState(false);
    const[selectedCaisse,setSelectedCaisse]=useState(null);
    const { token, setToken, user } = useStateContext();
    const tableRef = useRef();
    
    const navigate = useNavigate();

    const supprimer = (caisse) => {
        Swal.fire({
            title: "Confirmation",
            text: "Etes vous sûre de supprimer cette element ?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#2196F3",
            cancelButtonColor: "#d33",
            confirmButtonText: "Oui!",
            cancelButtonText: "Non!"
        }).then((result) => {
            if (result.isConfirmed) {
                axiosClient.delete('/caisse/' + caisse.id)
                    .then(response => {
                        toast.success('Opération effectuée avec succès!', {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        });
                        getCaisses();
                    }).catch(err => {
                        const response = err.response;
                        if (err.response.status === 401) {
                            setToken(null);
                        } else {
                            if (response.status === 401) {
                                setToken(null);
                            }
                            else {
                                if (response.status === 403) {
                                    navigate('/403');
                                } else {
                                    if (response.status === 404) {
                                        navigate('/404');
                                    }
                                    else {
                                        navigate('/500');
                                    }
                                }
                            }
                        }

                    })
            }
        });
    }

    useEffect(() => {
        getCaisses();
    }, [])

    const closeEditCaisse=()=>{
        setShowEditCaisse(false);
        setSelectedCaisse(null);
    }

    
    const getCaisses = () => {
        setLoading(true);
        axiosClient.get('/caisses')
            .then(response => {
                setCaisses(response.data.caisses);
                setLoading(false);
                setErrors(null);
            }).catch(err => {
                const response = err.response;
                if (response.status === 401) {
                    setToken(null);
                }
                else {
                    if (response.status === 422) {
                        setErrors(response.data.errors);
                        setLoading(false);
                        toast.error('Une erreur est survenue!', {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        });
                    } else {
                        if (response.status === 403) {
                            navigate('/403');
                        } else {
                            if (response.status === 404) {
                                navigate('/404');
                            }
                            else {
                                navigate('/500');
                            }
                        }
                    }

                }
            })
    }


    return (
        <>
            <Header title="Caisses " />
            {errors &&
                Object.keys(errors).map(key => (
                    <Alert key={key} message={errors[key][0]} />
                ))
            }
            
            
            <div className="flex my-2">
                <h1 className='text-red-500 font-bold'>Total solde: <NumberFormat valeur={caisses.reduce((n, { solde }) => n + parseFloat(solde), 0)}/> MRU</h1>
            </div>
            <div className='flex mt-3 overflow-auto'>
                <table ref={tableRef} className='table-auto' >
                    <thead>
                        <tr>
                            <th>code</th>
                            <th>Caisse </th>
                            <th>Agence</th>
                            <th>Utilisateur</th>
                            <th>Solde</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    {loading ? <tbody><tr className='content-center items-center'><td className='content-center items-center' colSpan="8"><Loading /></td></tr></tbody> :
                        <tbody>
                            {caisses.map(o => (
                                <tr>
                                    <td>{o.code}</td>
                                    <td>{o.nom}</td>
                                    <td>{o.user.agence.nom}</td>
                                    <td>{o.user.nom}</td>
                                    <td>{o.solde}</td>
                                    <td>
                                        <div className="flex justify-center w-full items-center gap-2">
                                            
                                            <ActionButton onClick={() => {
                                                setSelectedCaisse(o);
                                                setShowEditCaisse(true);
                                            }} color="bg-blue-500" icon={<FaEdit />} textColor="text-white" title="Modifier" />
                                            
                                        </div>
                                    </td>
                                </tr>
                            ))

                            }
                        </tbody>
                    }

                </table>
            </div>
            {
                showEditCaisse ? <EditCaisse editedCaisse={selectedCaisse} onClose={closeEditCaisse}/> : <></>
            }
            

        </>
    )
}

export default Caisses