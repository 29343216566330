import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from "react-router-dom";
import Loading from '../components/Loading';
import { useStateContext } from '../contexts/ContextProvider';
import axiosClient from '../axios-client';
import Alert from '../components/Alert';
import { Button } from '../components';
import { IoMdLogIn } from 'react-icons/io';
import Icon from '../data/icon.png';
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

const Login = () => {
  const navigate = useNavigate();
  const [loadin, setLoading] = useState(false);
  const usernameRef = useRef();
  const passwordRef = useRef();
  const { setToken, setUser } = useStateContext();
  const [errors, setErrors] = useState(null);
  const [fireToken,setFireToken]=useState(null);
  const firebaseConfig = {
    apiKey: "AIzaSyD8lrQYfQxKh_teGwuiOLKTcaKFHQM5zkw",
    authDomain: "cainotification.firebaseapp.com",
    projectId: "cainotification",
    storageBucket: "cainotification.appspot.com",
    messagingSenderId: "985679398235",
    appId: "1:985679398235:web:3561ed323a7db70dbc467e",
    measurementId: "G-06HLFD43RR"
  };

  // Initialize Firebase
  const app = initializeApp(firebaseConfig);
  const messaging = getMessaging(app);
  const requestPermission = () => {

    console.log('requesting user permission');
    Notification.requestPermission().then(permission => {
      console.log("dsafdsfds");
      if (permission === "granted") {
        console.log("granted");
        getToken(messaging,).then(currentToken => {
          if (currentToken) {
            console.log("hohohohohoh:"+currentToken);
            setFireToken(currentToken);

          } else {
            console.log("Faiiiiiled");
          }
        }).catch(err => {
          console.log("dfsgdffsgs" + err);
        })
      } else {
        console.log("deniiiiiide");
      }
    })
  };

  useEffect(()=>{
    requestPermission();
  },[]);
  const login = (ev) => {
    ev.preventDefault();
    setErrors(null);
    setLoading(true);
    const payload = {
      username: usernameRef.current.value,
      password: passwordRef.current.value,
      fireTok:fireToken,
    };
    axiosClient.post('/login', payload)
      .then((data) => {
        setUser(data.data.user);
        setToken(data.data.token);
      })
      .catch(err => {
        const response = err.response;
        if (response && response.status === 422) {
          setErrors(response.data.errors);
        }
        setLoading(false);
      });

  }



  return (
    <>
      <div className='flex  justify-center py-20'>
        <div className="flex bg-white w-full md:w-1/2 sm:w-full shadow-xl flex-col justify-center px-6 py-6 lg:px-8 rounded-2xl">

          <div className=" sm:mx-auto sm:w-full sm:max-w-sm">
            <img className="mx-auto h-36 w-auto" src={Icon} alt="Your Company" />
            <h2 className="mt-3 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">Se connecter</h2>
            
          </div>

          <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
            {errors &&
              Object.keys(errors).map(key => (
                <Alert key={key} message={errors[key][0]} />
              ))
            }
            <form className="space-y-6" >
              <div>
                <label className="block text-sm font-medium leading-6 text-gray-900">Username</label>
                <div className="mt-2">
                  <input ref={usernameRef} type="text" autocomplete="username" required className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
                </div>
              </div>

              <div>
                <div className="flex items-center justify-between">
                  <label className="block text-sm font-medium leading-6 text-gray-900">Password</label>

                </div>
                <div className="mt-2">
                  <input ref={passwordRef} name="password" type="password" autocomplete="current-password" required className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
                </div>
              </div>

              <div>
                {loadin ? <Loading />
                  :
                  <Button onClick={login} width='w-full' color="bg-blue-500" icon={<IoMdLogIn />} size="20" text="Se connecter" textColor="text-white" />

                }
              </div>
            </form>


          </div>
        </div>
      </div>
    </>
  );
}

export default Login