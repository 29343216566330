import { Navigate, createBrowserRouter } from "react-router-dom";
import DefaultLayout from "./layouts/DefaultLayout";
import GuestLayout from "./layouts/GuestLayout";
import Login from "./pages/Login";
import Forbiden from "./pages/Forbiden";
import NotFound from "./pages/NotFound";
import ServerError from "./pages/ServerError";
import Users from "./pages/User/Users";
import Home from "./pages/Home";
import Situation from "./pages/situation/Situation";
import Depenses from "./pages/depense/Depenses";
import Alimentations from "./pages/alimentation/Alimentations";
import Historiques from "./pages/historique/Historiques";
import Sorties from "./pages/sortie/Sorties";
import Bulletins from "./pages/bulletins/Bulletins";
import Coefficients from "./pages/coefficients/Coefficients";
import Examens from "./pages/examens/Examens";
import Devoirs from "./pages/devoirs/Devoirs";
import Emploies from "./pages/emploies/Emploies";
import Agences from "./pages/agences/Agences";
import CaisseCentrales from "./pages/caisseCentrale/CaisseCentrales";
import Entrees from "./pages/entrees/Entrees";
import Dechargements from "./pages/dechargement/Dechargements";
import Paiements from "./pages/paiements/Paiements";
import SituationCaisse from "./pages/situation/SituationCaisse";
import Caisses from "./pages/caisses/Caisses";
import Privileges from "./pages/privileges/Privileges";
import Documents from "./pages/documents/Documents";
const router=createBrowserRouter([
    {
        path:'/',
        element:<DefaultLayout/>,
        children:[
            {
                path:'/',
                element:<Navigate to="/home"/>
            },
            {
                path:'/home',
                element:<Home/>
            },
            
            {
                path:'/403',
                element:<Forbiden/>
            },
            {
                path:'/404',
                element:<NotFound/>
            },
            {
                path:'/agences',
                element:<Agences/>
            },
            {
                path:'/users',
                element:<Users/>
            },
            {
                path:'/caisseCentrales',
                element:<CaisseCentrales/>
            },
            {
                path:'/caisses',
                element:<Caisses/>
            },
            {
                path:'/entrees',
                element:<Entrees/>
            },
            
            {
                path:'/situations',
                element:<Situation/>
            },
            {
                path:'/situationcaisse',
                element:<SituationCaisse/>
            },
            {
                path:'/privileges',
                element:<Privileges/>
            },
            {
                path:'*',
                element:<NotFound/>
            },
            {
                path:'/depenses',
                element:<Depenses/>
            },
            {
                path:'/paiements',
                element:<Paiements/>
            }, 
            {
                path:'/alimentations',
                element:<Alimentations/>
            },
            {
                path:'/dechargements',
                element:<Dechargements/>
            },
            {
                path:'/sorties',
                element:<Sorties/>
            }, 
            {
                path:'/historiques',
                element:<Historiques/>
            },
            {
                path:'/bulletins',
                element:<Bulletins/>
            },
            {
                path:'/coefficients',
                element:<Coefficients/>
            }, 
            {
                path:'/examens',
                element:<Examens/>
            },
            {
                path:'/devoirs',
                element:<Devoirs/>
            },
            {
                path:'/emploies',
                element:<Emploies/>
            },
            {
                path:'/documents',
                element:<Documents/>
            },
            
            
        ]
    },
    {
        path:'/',
        element:<GuestLayout/>,
        children:[
            {
                path:'/login',
                element:<Login/>
            },
            {
                path:'/404',
                element:<NotFound/>
            },
            {
                path:'*',
                element:<NotFound/>
            },
            
            
        ]
    },
    {
        path:'/500',
        element:<ServerError/>
    },
    {
        path:'*',
        element:<NotFound/>
    }
    
    
])

export default router;